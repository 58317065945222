@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400&display=swap');

body,
.instrument-sans-font {
  font-family: "Instrument Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

a:active,
li:active,
label:active,
input:active,
select:active,
textarea:active,
button:active,
.btn {
  outline-style: none !important;
  box-shadow: none !important;
}

a:focus,
li:focus,
label:focus,
input:focus,
select:focus,
textarea:focus,
button:focus,
.btn {
  outline: 0 !important;
  box-shadow: none !important;
}

.login-page {
  background: linear-gradient(180deg, #f3f4ff 0%, #ebf1ff 100%);
}

.dropzone {
  border: 2px dashed #007bff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.dropzone.active {
  background-color: #e2e6ea;
}

.dropzone:hover {
  background-color: #e9ecef;
}

.dropzone p {
  margin: 10px 0 0;
  font-size: 16px;
  color: #6c757d;
}

h1 {
  color: #343a40;
}

input:focus {
  border-color: #222 !important;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  33% {background-size: 20% 10% ,20% 100%,20% 100%}
  50% {background-size: 20% 100%,20% 10% ,20% 100%}
  66% {background-size: 20% 100%,20% 100%,20% 10% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}

/* tasks */
.task-item .card-header {
  padding: 0;
}

.task-item .btn-link {
  text-decoration: none;
  color: inherit;
}

.task-item .btn-link:hover,
.task-item .btn-link:focus {
  text-decoration: none;
  color: inherit;
}

.task-item .collapse {
  transition: height 0.35s ease;
}

.task-item .bi {
  transition: transform 0.35s ease;
}

.task-item .btn-link[aria-expanded="true"] .bi {
  transform: rotate(180deg);
}
